import BNBIcon from '@/assest/images/BNBChain.png';
import ComboIcon from '@/assest/images/Combo.png';
import LumozNFT from '@/assest/images/Lumoz.png';
import LumzzkIcon from '@/assest/images/Lumzzk.png';
import NftBNB from '@/assest/images/NftBNB.png';
import NftCombo from '@/assest/images/NftCombo.png';
import NftScroll from '@/assest/images/NftScroll.png';
import ScrollIcon from '@/assest/images/Scroll.png';
import TaikoIcon from '@/assest/images/Taiko.png';
import TaikoNFT from '@/assest/images/TaikoNFT.png';
import victionIcon from '@/assest/images/Viction.png';
import VictionNFT from '@/assest/images/VictionNFT.png';
import opBNBIcon from '@/assest/images/opBNB.png';
import NftTreasuryBox from '@/assest/images/treasuryBox.png';
import { ComboTestnet, LumozzkEVMTestnet, ScrollSepolia, TaikoJolnir, Viction, opBNB } from '@particle-network/chains';
import { getCurrentChainId } from './chain';

const isDevelopment = Boolean(window.location.port);

const isDebug = window.location.host.includes('debug');

const isProduction = !isDevelopment && !isDebug;

window.__PARTICLE_ENVIRONMENT__ = isProduction ? 'production' : 'development';

const DebugEnv = {
    [opBNB.id]:
        window.location?.host.includes('airdrop') || window.location?.search.includes('opBNBTreasuryBox')
            ? {
                  PROJECT_ID: '8787c72f-2fb0-429b-bccf-cc284475a0ed',
                  CLIENT_KEY: 'clOAYWHKEXB8EWvK1ZhBfMneFXkTi84ARyoeLsly',
                  APP_ID: '764aa795-0464-49a7-a179-3bd58b5baac6',
              }
            : {
                  PROJECT_ID: 'ce4e094f-49e7-478d-8ae8-8c749e43cd6a',
                  CLIENT_KEY: 'cFSixa1sZgubkIXU0Dx7EKViYIeadYm5OGQufru2',
                  APP_ID: 'd112f2ef-7bf1-49a7-befa-f8ef6288872c',
              },

    [ComboTestnet.id]: {
        PROJECT_ID: '7ae78c8a-0358-42ff-9f3c-d79d0e5b325a',
        CLIENT_KEY: 'cAwXhxfGQ5ABPkgU3TZ9tayimrGfSpw8SiDP2pVa',
        APP_ID: 'f638d509-0419-4e20-9c6f-833df32cd50c',
    },

    [ScrollSepolia.id]: {
        PROJECT_ID: '11fc3744-3778-4bbf-ae66-b67587d4df5f',
        CLIENT_KEY: 'cpKhY3E7S6LKTf0u3Ine8iAQc0XdXGgTqAOsYQXD',
        APP_ID: '1cdffd9a-03a7-4ed9-ae14-dc9715fb029b',
    },
    [Viction.id]: {
        PROJECT_ID: 'ef2e3344-1c52-40aa-a79d-f185839bea2f',
        CLIENT_KEY: 'cewLwkdPja6Y0Z9phTpX24CquRClgieTBeZcetl7',
        APP_ID: 'b52da5ac-7cea-4c8d-b50d-7f990f2faac2',
    },
    [LumozzkEVMTestnet.id]: {
        PROJECT_ID: '40512842-3fa0-4907-9d4c-6bae90f6bef5',
        CLIENT_KEY: 'cKQhuIG708zvFlF5NVIxJ8tYcaX7YE5bMs33VWFM',
        APP_ID: '0a2ddec1-e678-4560-ab37-55e4f66db64a',
    },
    [TaikoJolnir.id]: {
        PROJECT_ID: '3ab0be69-29c3-41d8-912f-b46a7666f101',
        CLIENT_KEY: 'cagmpzaUfmAoP3KmQoTvFq9AQP2qT2FqgJvML3mD',
        APP_ID: 'eff8b835-d369-4bf6-8b27-7294bd4dc9da',
    },
};

const ProductionEnv = {
    [opBNB.id]:
        window.location?.host.includes('airdrop') || window.location?.search.includes('opBNBTreasuryBox')
            ? {
                  PROJECT_ID: '4f752011-bf4e-4cef-a09e-20a08db26624',
                  CLIENT_KEY: 'cZ5WfuJmmTrBvoB4JL2RGrPiNpguvv38LdnZWXsb',
                  APP_ID: '3cb75a6f-fa92-4239-bb54-9593f53d3726',
              }
            : {
                  PROJECT_ID: '6f1d6acc-e87e-4b51-b761-2b8278922717',
                  CLIENT_KEY: 'cO7xwHGSiIo8qkGxy0RxUS9egUMuyPeEvHIyASdl',
                  APP_ID: 'd2034ee7-f532-4b9b-b208-ffdbbce6561a',
              },
    [ComboTestnet.id]: {
        PROJECT_ID: 'e1551846-9cd4-4c52-9016-8814effe2f39',
        CLIENT_KEY: 'c6x3op9eujQ4sWZgiy79Q7I7WR3FuzTnHZsNM5QL',
        APP_ID: 'ef78228f-6918-41ea-b148-5d0b2a573c16',
    },
    [ScrollSepolia.id]: {
        PROJECT_ID: '769f1bd0-f918-433f-8dc9-ce5ad360357e',
        CLIENT_KEY: 'cKEGkIzUYutcckiU6D7H7WCVpZPsybqdFsyj4dWp',
        APP_ID: '5c23e535-b3f6-4797-a6f1-3975e15298d4',
    },
    [Viction.id]: {
        PROJECT_ID: '65722b0e-8bca-45d6-be31-ce1e4c037bc8',
        CLIENT_KEY: 'cpuvH7BwMjufNO0FA0pseETdOi2oK18ziT4pZnLH',
        APP_ID: 'ae12969f-12b4-4a73-8de5-c8a620ba2dc9',
    },
    [LumozzkEVMTestnet.id]: {
        PROJECT_ID: '4f752011-bf4e-4cef-a09e-20a08db26624',
        CLIENT_KEY: 'cZ5WfuJmmTrBvoB4JL2RGrPiNpguvv38LdnZWXsb',
        APP_ID: '3cb75a6f-fa92-4239-bb54-9593f53d3726',
    },
    [TaikoJolnir.id]: {
        PROJECT_ID: '4f752011-bf4e-4cef-a09e-20a08db26624',
        CLIENT_KEY: 'cZ5WfuJmmTrBvoB4JL2RGrPiNpguvv38LdnZWXsb',
        APP_ID: '3cb75a6f-fa92-4239-bb54-9593f53d3726',
    },
};
console.log(333, getCurrentChainId());
export const BundlerApiPrefix = ['https://', isProduction ? 'bundler' : 'bundler-debug', '.particle.network'].join('');

export const PaymasterApiPrefix = [
    'https://',
    isProduction ? 'paymaster' : 'paymaster-debug',
    '.particle.network',
].join('');

export const EnvData = (isProduction ? ProductionEnv : DebugEnv)[getCurrentChainId()];
console.log('project', EnvData);
const ComboConfig = {
    ConstractAddress: '0x5bA7C86EEc21eCC5C5edBb720e4A52c851F7e2a6',
    Chain: ComboTestnet,
    title: 'Particle @ COMBO',
    description:
        "COMBO is a leading provider of scaling solutions for Web3 game development. By leveraging the world's top game engine, COMBO is building an open-source, decentralized, game-oriented Layer2 that is accessible to everyone. It aims to maximize the potential of Web3 games by connecting game developers with the entire ecosystem in an efficient, affordable, and secure way.",
    homepage: 'https://combonetwork.io/',
    icon: ComboIcon,
    getScanUrl: (address: string) => {
        return [ComboTestnet.blockExplorerUrl, 'address', address].join('/');
    },
    getBlockExplorerUrl: (txHash: string) => {
        return [ComboTestnet.blockExplorerUrl, 'tx', txHash].join('/');
    },
    transferPlaceholder: 'Please enter your Wallet used in Galxe',
    finishedText: 'You have finished the quests for Particle x Combo Campaign',
    nftIcon: NftCombo,
    imgStyle: {},
    CampaignLink: 'https://galxe.com/ParticleNetwork/campaign/GCLJmUBPYg',
    CampaignLinkText: 'Go to Galxe and Claim Reward',
};

const ScrollConfig = {
    ConstractAddress: '0xC1C4aa02F5B65D1A885eC5cC03B39598047390eB',
    Chain: ScrollSepolia,
    title: 'Particle @ Scroll',
    description:
        "Scroll is designed by and for Ethereum developers. A swift, reliable and scalable Layer 2 blockchain, Scroll extends Ethereum's capabilities, enabling apps to scale without any surprises. Thanks to bytecode-level compatibility, existing Ethereum apps can migrate onto Scroll as-is, and at a significantly reduced cost.",
    homepage: 'https://scroll.io/',
    icon: ScrollIcon,
    getScanUrl: (address: string) => {
        return [ScrollSepolia.blockExplorerUrl, 'address', address].join('/');
    },
    getBlockExplorerUrl: (txHash: string) => {
        return [ScrollSepolia.blockExplorerUrl, 'tx', txHash].join('/');
    },
    transferPlaceholder: 'Please enter your Wallet used in Galxe',
    finishedText: 'You have finished the quests for Particle x Scroll Campaign',
    nftIcon: NftScroll,
    imgStyle: {},
    CampaignLink: 'https://galxe.com/ParticleNetwork/campaign/GCVcLUH9fe',
    CampaignLinkText: 'Go to Galxe and Claim Reward',
};

const opBNBConfig = {
    ConstractAddress: '0xC1C4aa02F5B65D1A885eC5cC03B39598047390eB',
    Chain: opBNB,
    title: 'Particle @ opBNB',
    description:
        "opBNB is a high-performance layer-2 solution within the BNB ecosystem, built using the OP Stack. Leveraging its block size of 100M, opBNB's gas fees remain stable and low cost, making it a great solution for widespread adoption across multiple digital environments.",
    homepage: 'https://opbnb.bnbchain.org/en',
    icon: opBNBIcon,
    transferPlaceholder: 'Please enter your Wallet used in Galxe',
    getScanUrl: (address: string) => {
        return `${opBNB.blockExplorerUrl}/address/${address}`;
    },
    getBlockExplorerUrl: (txHash: string) => {
        return `${opBNB.blockExplorerUrl}/tx/${txHash}`;
    },
    finishedText: 'You have finished the quests for Particle x opBNB Campaign',
    nftIcon: NftBNB,
    imgStyle: {},
    CampaignLink: 'https://galxe.com/ParticleNetwork/campaign/GCorLUHaZn',
    CampaignLinkText: 'Go to Galxe and Claim Reward',
};

const LumozzkEVMTestnetConfig = {
    ConstractAddress: '0x74883445AF29A502a1866d1E847d9B2c5fF74ac4',
    Chain: LumozzkEVMTestnet,
    title: 'Particle @ Lumoz',
    description:
        'Lumoz is a decentralized ZK-RaaS (ZK-Rollup as a Service) platform as well as a PoW (Proof of Work) network that facilitates ZKP (Zero-Knowledge Proof) mining. Lumoz takes care of consensus and data availability on layer 2, and developers can use a general-purpose ZK-rollups SDK to deploy their dApps on Opside layer 3 without the need to maintain any infrastructure.',
    homepage: 'https://lumoz.org/',
    icon: LumzzkIcon,
    getScanUrl: (address: string) => {
        return `${LumozzkEVMTestnet.blockExplorerUrl}/address/${address}`;
    },
    getBlockExplorerUrl: (txHash: string) => {
        return `${LumozzkEVMTestnet.blockExplorerUrl}/tx/${txHash}`;
    },
    transferPlaceholder: 'Please enter your Wallet used in Galxe',
    finishedText: 'You have finished the quests for Particle x Lumoz Chain Campaign',
    nftIcon: LumozNFT,
    imgStyle: {},
    CampaignLink: 'https://app.questn.com/quest/828086170181480628',
    CampaignLinkText: 'Go to Galxe and Claim Reward',
};

const VictionConfig = {
    ConstractAddress: '0x74883445AF29A502a1866d1E847d9B2c5fF74ac4',
    Chain: Viction,
    title: 'Particle @ Viction',
    description:
        'Viction, previously known as TomoChain, is a people-centric layer-1 blockchain that provides zero-gas transactions and heightened security, making Web3 accessible and safe for everyone. With a design emphasis on user experience, Viction prioritizes zero-gas transactions through the innovative TRC25 token standard, alongside speed, security, and scalability, all contributing to a more secure and open world.',
    homepage: 'https://viction.xyz/',
    icon: victionIcon,
    getScanUrl: (address: string) => {
        return `${Viction.blockExplorerUrl}/address/${address}`;
    },
    getBlockExplorerUrl: (txHash: string) => {
        return `${Viction.blockExplorerUrl}/tx/${txHash}`;
    },
    transferPlaceholder: 'Please enter your Wallet used in Galxe',
    finishedText: 'You have finished the quests for Particle x Viction Chain Campaign',
    nftIcon: VictionNFT,
    imgStyle: {},
    CampaignLink: 'https://galxe.com/ParticleNetwork/campaign/GCHzvtUDzn',
    CampaignLinkText: 'Go to Galxe and Claim Reward',
};

const opBNBTreasuryBoxConfig = {
    ConstractAddress: '0x810E730096A96DaB760d7db5EdE067F508a59b01',
    Chain: opBNB,
    title: 'Particle @ BNB Chain',
    description:
        'Embark on a Treasure Hunt Adventure: Discover the BNB Treasure Box with Particle Network! Join us for a two week-long journey, featuring thrilling tasks on QuestN as well as on-chain activities like Gasless NFT Minting and Transfers with Particle Network. 🗝️ Gather your treasure map to unlock the hunt and set sail on this epic treasure-hunting quest!',
    homepage: 'https://www.bnbchain.org/en',
    icon: BNBIcon,
    getScanUrl: (address: string) => {
        return `${opBNB.blockExplorerUrl}/address/${address}`;
    },
    getBlockExplorerUrl: (txHash: string) => {
        return `${opBNB.blockExplorerUrl}/tx/${txHash}`;
    },
    transferPlaceholder: 'Please enter your Wallet used in QuestN',
    finishedText: 'You have finished the quests for Particle x BNB Chain Campaign',
    nftIcon: NftTreasuryBox,
    imgStyle: {
        width: 350,
        height: 350,
        transform: 'translate(-56px, -70px)',
    },
    CampaignLink: 'https://app.questn.com/quest/828086170181480628',
    CampaignLinkText: 'Go to QuestN and Claim Reward',
};

const TaikoJolnirConfig = {
    ConstractAddress: '0x74883445AF29A502a1866d1E847d9B2c5fF74ac4',
    Chain: TaikoJolnir,
    title: 'Particle @ Taiko',
    description:
        'Taiko aims to be a fully Ethereum-equivalent ZK-Rollup in order to scale Ethereum while maintaining its technical and fundamental principles. As a fully Ethereum-equivalent rollup, it will enable dApp developers and users to leverage the power of Ethereum Layer 1 (L1) without making any changes to their existing dApps.',
    homepage: 'https://taiko.xyz/',
    icon: TaikoIcon,
    getScanUrl: (address: string) => {
        return `${TaikoJolnir.blockExplorerUrl}/address/${address}`;
    },
    getBlockExplorerUrl: (txHash: string) => {
        return `${TaikoJolnir.blockExplorerUrl}/tx/${txHash}`;
    },
    transferPlaceholder: 'Please enter your Wallet used in Galxe',
    finishedText: 'You have finished the quests for Particle x Taiko Chain Campaign',
    nftIcon: TaikoNFT,
    imgStyle: {},
    CampaignLink: 'https://app.questn.com/quest/828086170181480628',
    CampaignLinkText: 'Go to Galxe and Claim Reward',
};

const ConfigMap = {
    [ComboTestnet.id]: ComboConfig,
    [ScrollSepolia.id]: ScrollConfig,
    [opBNB.id]:
        window.location?.host.includes('airdrop') || window.location?.search.includes('opBNBTreasuryBox')
            ? opBNBTreasuryBoxConfig
            : opBNBConfig,
    [Viction.id]: VictionConfig,
    [LumozzkEVMTestnet.id]: LumozzkEVMTestnetConfig,
    [TaikoJolnir.id]: TaikoJolnirConfig,
};

export const CampaignConfig = ConfigMap[getCurrentChainId()];
