/**
 * 本次活动有 3 个链, 分别是: opBNB, ComboTestnet, ScrollSepolia
 * 本地环境: 通过 sessionStorage 来判断
 * 测试环境和线上环境: 通过域名判断
 */

import { ComboTestnet, LumozzkEVMTestnet, ScrollSepolia, TaikoJolnir, Viction, opBNB } from '@particle-network/chains';
import { Modal, Radio, Space } from 'antd';

const ChainList = [ComboTestnet, ScrollSepolia, opBNB, Viction, LumozzkEVMTestnet, TaikoJolnir];
const activityObj = {
    Combo: ComboTestnet,
    Scroll: ScrollSepolia,
    opBNB: opBNB,
    opBNBTreasuryBox: opBNB,
    viction: Viction,
    Lumoz: LumozzkEVMTestnet,
    Taiko: TaikoJolnir,
};
const isDevelopment = Boolean(window.location.port);

const ChainIdKey = 'UserSelectChainId';

const chainId = Number(window.sessionStorage.getItem(ChainIdKey));

const ChainSelect = () => {
    const onChange = (e: any) => {
        const { value }: { value: 'Combo' | 'Scroll' | 'opBNB' | 'opBNBTreasuryBox' } = e.target;
        window.sessionStorage.setItem(ChainIdKey, String(activityObj[value]?.id));

        const url = new URL(window.location.href);
        url.searchParams.set('type', value);
        window.location.href = url.href;
    };
    return (
        <Radio.Group onChange={onChange} defaultValue={'Combo'}>
            <Space direction="vertical">
                {Object.keys(activityObj).map((name) => {
                    return (
                        <Radio value={name} key={name}>
                            {name}
                        </Radio>
                    );
                })}
            </Space>
        </Radio.Group>
    );
};

export const showChainSelector = () => {
    Modal.error({
        title: '请选择你开发的活动',
        content: <ChainSelect />,
        keyboard: false,
        okButtonProps: {
            style: {
                display: 'none',
            },
        },
    });
};

if (isDevelopment) {
    if (!ChainList.map((v) => v.id).includes(Number(chainId))) {
        showChainSelector();
    }
}

export const getCurrentChainId = () => {
    // 根据域名判断
    if (!isDevelopment) {
        const { host } = window.location;
        if (host.includes('combo')) {
            return ComboTestnet.id;
        }
        if (host.includes('scroll')) {
            return ScrollSepolia.id;
        }
        if (host.includes('opbnb')) {
            return opBNB.id;
        }
        if (host.includes('bnb')) {
            return opBNB.id;
        }
        if (host.includes('viction')) {
            return Viction.id;
        }
        if (host.includes('lumoz')) {
            return LumozzkEVMTestnet.id;
        }
        if (host.includes('taiko')) {
            return TaikoJolnir.id;
        }
        throw new Error('未知域名');
    }
    document.title = ['Particle ❤', getCurrentChainName(), 'Chain'].join(' ');
    if (!ChainList.map((v) => v.id).includes(Number(chainId))) {
        return ChainList[0].id;
    }
    return chainId;
};

export const getCurrentChainName = () => {
    const currentChain = ChainList.find((v) => v.id === chainId);
    return currentChain?.name ?? '';
};
